import React from "react"
import SEO from "../components/seo"
import Layout from "../components/templates/layout"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Não encontrada" />
    <h1>Não encontrada</h1>
    <p>Você tentou acessar uma rota que não existe!😥</p>
  </Layout>
)

export default NotFoundPage
